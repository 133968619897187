<template>
  <div :class="classes" @click="handleOnClick">
    <slot><a>Add a bank account</a></slot>
  </div>
</template>

<script>
import { getEnvVar } from '@utils';

export default {
  name: 'PlaidLink',
  props: {
    plaidUrl: {
      type: String,
      default: 'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
    },
    institution: String,
    selectAccount: Boolean,
    token: String,
    product: {
      type: [String, Array],
      default() { return ['auth']; },
    },
    language: String,
    countryCodes: Array,
    isWebView: Boolean,
    clientName: {
      type: String,
      default: 'Kinside',
    },
    webhook: String,
    onLoad: Function,
    onSuccess: Function,
    onExit: Function,
    onEvent: Function,
    classes: String,
  },
  data() {
    return {
      timeoutId: undefined,
    };
  },
  created() {
    this.loadScript(this.plaidUrl)
      .then(this.onScriptLoaded)
      .catch(this.onScriptError);
  },
  beforeDestroy() {
    if (window.linkHandler) {
      window.linkHandler.exit();
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    onScriptError() {
      // TODO: NL: 2022/11/09: Do something with this error.
    },
    onScriptLoaded() {
      window.linkHandler = window.Plaid.create({
        clientName: this.clientName,
        env: getEnvVar('PLAID_ENV'),
        key: getEnvVar('PLAID_PUBLIC_KEY'),
        onExit: this.onExit,
        onEvent: this.onPlaidEvent,
        onSuccess: this.onSuccess,
        product: this.product,
        selectAccount: this.selectAccount,
        token: this.token,
        webhook: this.webhook,
      });
    },
    handleOnClick() {
      const institution = this.institution || null;
      if (window.linkHandler) {
        window.linkHandler.open(institution);
      } else {
        this.timeoutId = setTimeout(this.handleOnClick, 100);
      }
    },
    loadScript(src) {
      return new Promise(((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve();
          return;
        }
        const el = document.createElement('script');
        el.type = 'text/javascript';
        el.async = true;
        el.src = src;
        el.addEventListener('load', resolve);
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        document.head.appendChild(el);
      }));
    },

    onPlaidEvent(eventName, metadata) {
      if (eventName === 'OPEN') {
        window.plaidOpened = true;
      }

      if (eventName === 'EXIT') {
        window.plaidOpened = false;
      }

      if (eventName === 'HANDOFF') {
        this.$emit('handoff');
      }

      if (this.onEvent) {
        this.onEvent(eventName, metadata);
      }
    },
  },
  watch: {
    $data: {
      handler() {
        this.onScriptLoaded();
      },
      deep: true,
    },
  },
};
</script>
