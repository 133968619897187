<template>
  <modal
    :is-open="isOpen"
    @close="onClose"
  >
    <template #header>
      <h6>How much would you like to charge to {{ methodName }}?</h6>
    </template>
    <k-radio v-model="chargedAmount" @change="updateAmount" name="wholeAmount" value="wholeAmount">
      The whole amount
    </k-radio>
    <k-radio v-model="chargedAmount" name="partialAmount" value="partialAmount">
      A partial amount
    </k-radio>
    <div class="input-wrapper">
      <currency-input
        v-model.number="amount"
        @blur="onAmountChanged"
        @change="toggleChargedAmount"
        class="font-heading-60 input-wrapper__input"/>
    </div>
    <box v-if="isNaviaFsa" color="gold">
      Your current balance is {{naviaBalance}}
    </box>
    <box v-else-if="isFsa" color="gold">
      <b>Important!</b>
      Please ensure your FSA account has sufficient funds before continuing.
    </box>
    <template #footer>
      <system-button
        class="button-to-the-left"
        style-type="clear"
        color="rust"
        @click="removeMethod"
      >
        Remove method
      </system-button>

      <system-button
        style-type="secondary"
        @click="onClose">
        Cancel
      </system-button>
      <system-button
        :disabled="isAmountTooLow || isAmountTooHigh"
        @click="updateMethod">
        Save
      </system-button>
    </template>
  </modal>
</template>

<script>
import Modal from '@components/modal/modal.vue';
import CurrencyInput from '@components/inputs/currency_input.vue';
import KRadio from '@components/inputs/radio.vue';
import SystemButton from '@components/buttons/system-button.vue';
import { isCurrencyAmountTooLow, getPaymentMethodName, getDollarFormat } from '@utils';
import Box from '@components/box.vue';
import { recalculateAmount } from '../../../../common/stores/modules/payments.service';

export default {
  components: {
    KRadio,
    Modal,
    CurrencyInput,
    SystemButton,
    Box,
  },
  props: {
    wholeAmount: {
      type: Number,
      required: true,
    },
    initialAmount: {
      type: Number,
      required: true,
    },
    methodId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
    },
    isOpen: {
      type: Boolean,
    },
    currentPayment: {
      type: Object,
    },
  },
  data() {
    return {
      amount: this.initialAmount,
      chargedAmount: this.initialAmount === this.wholeAmount ? 'wholeAmount' : 'partialAmount',
    };
  },
  computed: {
    isAmountTooLow() {
      return isCurrencyAmountTooLow(this.amount);
    },
    isAmountTooHigh() {
      return this.amount > this.wholeAmount
      || (this.isNaviaFsa && this.amount > this.$store.state.payments.navia.balance);
    },
    methodData() {
      return this.$store.state.payments.paymentMethods[this.methodId] || {};
    },
    methodName() {
      return this.title || getPaymentMethodName(this.methodData);
    },
    isFsa() {
      return this.methodData.fsa;
    },
    isNaviaFsa() {
      return this.methodId === Number(this.$store.state.payments.navia.cardId);
    },
    naviaBalance() {
      return getDollarFormat(this.$store.state.payments.navia.balance);
    },
  },
  methods: {
    async removeMethod(methodTobeRemovedId) {
      this.$emit('remove-method', methodTobeRemovedId);
    },

    onClose() {
      this.$emit('close');
    },

    updateMethod() {
      const secondMethod = this.currentPayment.paymentRequestMethods
        .filter((method) => method.paymentMethodId !== this.methodId)[0];
      if (secondMethod && this.chargedAmount === 'wholeAmount') {
        // if the user choose whole amount and save then the other
        // payment method (if exists) needs to be removed
        this.removeMethod(secondMethod.paymentMethodId);
      }
      this.$emit('update-amount', this.amount);
      this.onClose();
    },

    onAmountChanged() {
      if (this.isAmountTooLow) {
        this.amount = 50;
      } else if (this.isAmountTooHigh) {
        this.amount = this.wholeAmount;
      }
    },
    toggleChargedAmount() {
      this.chargedAmount = this.amount === this.wholeAmount ? 'wholeAmount' : 'partialAmount';
    },
    async updateWholeAmount() {
      // in case of split payments: the current whole amount is the total amount
      // of the two payment methods including commission so
      // when a user selects whole amount -> we need to recalculate
      // the whole amount as the second payment (if exists) should be excluded from the total
      const paymentRequestMethods = this.currentPayment.paymentRequestMethods
        .filter((method) => method.paymentMethodId === this.methodId);
      const data = await recalculateAmount({
        paymentRequestMethods,
        totalAmount: this.currentPayment.amount,
      });
      this.amount = data.totalToBeCharged;
      this.wholeAmount = this.amount;
    },
    updateAmount() {
      if (this.chargedAmount === 'wholeAmount' && this.amount !== this.wholeAmount) {
        this.updateWholeAmount();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__input {
    &::v-deep .k-input .k-input__formatted-value,
    &::v-deep .k-input .k-input__input-field {
      height: unset;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

button.systemBtn.button-to-the-left {
  margin-right: auto;
  margin-left: 0;
}
</style>
