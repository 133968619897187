<template>
  <k-input
    type="currency"
    class="currency-input"
    :value="valueInDollars"
    :formatted-value="formattedCurrency"
    v-bind="$attrs"
    v-on="inputListeners"
    :size="$attrs.size || 7"
    :min="minValue"
    :max="maxValue"
    :error="hasError"
    placeholder="0.00"
    :step="0.01"
  >
  </k-input>
</template>

<script>
import KInput from './input.vue';
import { roundAmount, isCurrencyAmountTooLow } from '../../utils';

function getValueToSend(value) {
  const isValueDefined = typeof value === 'string' && value.length;
  return isValueDefined ? (Number(value) * 100) : undefined;
}

function emitEvent(vm, event, value) {
  const valueToSend = getValueToSend(value);
  vm.$emit(event, valueToSend);
  vm.$emit('error', vm.withLowPaymentError && isCurrencyAmountTooLow(value));
}

export default {
  name: 'currency-input',
  components: {
    KInput,
  },

  props: {
    value: Number,
    error: Boolean,
    withLowPaymentError: {
      type: Boolean,
      default: true,
    },
    min: Number,
    max: Number,
  },

  data() {
    return {
      isFocused: false,
    };
  },

  computed: {
    formattedCurrency() {
      return (!!this.valueInDollars || this.valueInDollars === 0) && this.valueInDollars.toFixed(2);
    },
    inputListeners() {
      const vm = this;
      // event value is passed as string or undefined
      return {
        ...this.$listeners,
        change(value) {
          emitEvent(vm, 'change', value);
        },
        input(value) {
          emitEvent(vm, 'input', value);
        },
        blur(value) {
          vm.isFocused = false;
          emitEvent(vm, 'blur', value);
        },
        focus(value) {
          vm.isFocused = true;
          emitEvent(vm, 'focus', value);
        },
      };
    },

    valueInDollars() {
      return typeof this.value === 'number' ? roundAmount(this.value / 100) : '';
    },

    minValue() {
      if (this.min !== undefined) {
        return roundAmount((Number(this.min) || 0) / 100);
      }
      return this.withLowPaymentError ? 0.5 : 0;
    },

    maxValue() {
      if (this.max !== undefined && !Number.isNaN(Number(this.max))) {
        return roundAmount(Number(this.max) / 100);
      }
      return undefined;
    },

    hasError() {
      return (this.withLowPaymentError && isCurrencyAmountTooLow(this.value)) || this.error;
    },
  },
};
</script>
