<template>
  <div class="stripe-form">
    <k-input label="Card number" :error="this.errors.cardNumber" class="k-input--stripe">
      <div id="cardNumber" :tabIndex="getTabIndex('cardNumber')" @focus="onElementFocus"></div>
    </k-input>
    <div class="stripe-form__date-cvc-container">
      <k-input label="Expiry date" :error="this.errors.cardExpiry" class="k-input--stripe">
        <div id="cardExpiry" :tabIndex="getTabIndex('cardExpiry')" @focus="onElementFocus"></div>
      </k-input>
      <k-input label="CVC" :error="this.errors.cardCvc" class="k-input--stripe">
        <div id="cardCvc" :tabIndex="getTabIndex('cardCvc')" @focus="onElementFocus"></div>
      </k-input>
    </div>
  </div>
</template>

<script>
import KInput from '@components/inputs/input.vue';
/* global stripe */

export default {
  components: {
    KInput,
  },
  props: {
    elements: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch('payments/loadStripe')
      .then(() => {
        this.mountStripeElements();
      })
      .finally(() => {
        this.loading = false;
      });
  },
  beforeDestroy() {
    if (this.errors) {
      const methodKeys = Object.keys(this.elements);

      methodKeys.forEach((key) => {
        if (this.elements[key]) {
          this.elements[key].removeEventListener('change');
        }
      });
    }
  },
  data() {
    return {
      loading: false,
      name: '',
      stripe: undefined,
      focusedId: null,
    };
  },
  methods: {

    mountStripeElements() {
      const fonts = [
        {
          family: 'questa-sans',
          src: 'url(https://use.typekit.net/af/5cf7eb/00000000000000003b9af670/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3)',
          weight: '500',
        },
      ];

      const elements = stripe.elements({ fonts, locale: 'en' });

      const methodKeys = Object.keys(this.elements);

      methodKeys.forEach((key) => {
        if (!this.elements[key]) {
          const options = this.getOptions(key);
          this.elements[key] = elements.create(key, options);
        }
        this.elements[key].mount(`#${key}`);
        if (this.errors) {
          this.elements[key].addEventListener('change', (event) => {
            if (event.error) {
              this.errors[key] = true;
            } else {
              this.errors[key] = false;
            }
          });
        }
      });
    },

    getOptions(type) {
      const classes = {
        base: 'k-input__input-field',
        focus: 'stripe-input--focus',
        invalid: 'stripe-input--error',
      };

      const elementOptions = {
        classes,
        style: {
          base: {
            fontFamily: 'questa-sans',
            fontSize: '18px',
            color: '#3b3b3b',
            fontVariant: 'lining-nums',
          },
          invalid: {
            color: '#C54813',
          },
        },
      };

      switch (type) {
        case 'cardCvc':
          return {
            ...elementOptions,
            placeholder: '###',
          };
        default:
          return elementOptions;
      }
    },

    getTabIndex(id) {
      if (id === this.focusedId) {
        return -1;
      }
      return 0;
    },

    onElementFocus(event) {
      const { id } = event.target;
      if (id && id !== this.focusedId) {
        this.elements[id].focus();
      }
      this.focusedId = id;
    },

  },

};
</script>
<style lang="scss" scoped>
.stripe-form {
  width: 100%;

  & > * {
    width: 100%;
  }

  &__date-cvc-container {
    display: flex;

    & > * {
      min-width: 80px;

      &:not(:last-child) {
        min-width: 120px;
        margin-right: 20px;
      }
    }
  }
}

.k-input--stripe::v-deep .k-input {
  max-height: 40px;
  border: 1px solid transparent;
}

.k-input__input-field {
  border: 1px solid var(--gray-80);
  border-radius: 8px;
}

.stripe-input--focus {
  box-shadow: 0 0 0 2px var(--viridian-5);
}

.stripe-input--error {
  border-color: var(--rust-50);
}
</style>
