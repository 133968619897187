<template>
  <div class="wrapper">
    <button
      class="method-card"
      :class="{ 'method-card--fsa': isFsa, 'method-card--error': error}"
      @click="onMethodClicked"
      :disabled="isApplied || $attrs.disabled"
    >
      <div class="method-card__summary">
        <div class="method-card__icon" :class="icon"></div>
        <div class="method-card__name">
          {{ methodName }}
        </div>
        <div v-if="showAmount" class="method-card__amount"
             :class="isApplied ? 'font-body-small': 'font-heading-10'">
          {{ isApplied ? 'Already applied' : formattedAmount }}
        </div>
      </div>
      <div class="method-card__additional-details">
        {{ additionalDetails }}
      </div>
    </button>
    <edit-method-modal
      v-if="editable && showEditModal"
      :is-open="showEditModal"
      :whole-amount="currentPayment.totalToBeCharged"
      :initial-amount="totalAmount || totalRemainingWithFee"
      :method-id="paymentMethodId"
      :title="title"
      :current-payment="currentPayment"
      @close="onHideEditModal"
      @remove-method="onRemoveMethod"
      @update-amount="onUpdatePaymentAmount"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getPaymentMethodName, getDollarFormat } from '@utils';
import EditMethodModal from './edit-method-modal.vue';
import { recalculateAmount } from '../../../../common/stores/modules/payments.service';

export default {
  components: {
    EditMethodModal,
  },
  props: {
    paymentMethodId: {
      type: Number,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    helperText: {
      type: String,
      default: undefined,
    },
    iconClass: {
      type: String,
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    isFsaCard: {
      type: Boolean,
      default: false,
    },
    isBankAccount: {
      type: Boolean,
      default: false,
    },
    isApplied: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: String,
      default: undefined,
    },
    showAmount: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showEditModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentPayment: 'payments/currentPayment',
      totalRemainingWithFee: 'payments/currentPaymentTotalRemainingWithFee',
    }),
    error() {
      if (!this.currentPayment?.error) {
        return undefined;
      }
      const { relatedObj } = this.currentPayment.error;
      if (!relatedObj) {
        return undefined;
      }
      const isErrorRelated = relatedObj.class === 'PaymentMethod' && relatedObj.id === this.paymentMethodId;
      return isErrorRelated && this.currentPayment.error;
    },
    totalAmount() {
      return this.currentPayment?.paymentRequestMethods?.find(
        (method) => method.paymentMethodId === this.paymentMethodId,
      )?.totalAmount;
    },
    formattedAmount() {
      return this.totalAmount !== undefined ? getDollarFormat(this.totalAmount) : '';
    },
    additionalDetails() {
      return this.helperText || this.error?.message || '';
    },
    methodData() {
      return this.$store.state.payments.paymentMethods[this.paymentMethodId];
    },
    isCard() {
      return this.methodData?.paymentMethodType?.toLowerCase() === 'card';
    },
    isBank() {
      const paymentMethodType = this.methodData?.paymentMethodType?.toLowerCase();
      return this.isBankAccount || paymentMethodType?.includes('bank');
    },
    methodName() {
      return this.title || getPaymentMethodName(this.methodData);
    },
    isFsa() {
      return this.methodData?.fsa || this.isFsaCard;
    },
    icon() {
      if (this.iconClass) {
        return this.iconClass;
      }
      if (this.isFsa) {
        return 'fas fa-credit-card-blank';
      }
      if (this.isBank) {
        return 'far fa-university';
      }
      if (this.methodData?.cardType) {
        return `fab fa-cc-${this.methodData.cardType}`;
      }
      return 'far fa-credit-card';
    },

  },

  methods: {
    onMethodClicked(evt) {
      this.$emit('click', evt);
      if (this.paymentMethodId && this.editable) {
        this.showEditModal = true;
      }
    },

    async onRemoveMethod(methodToBeRemovedId) {
      this.$store.dispatch('track/event', {
        category: 'payments',
        event: 'new_payment_remove_payment_row',
      });

      if (methodToBeRemovedId) {
        // for split payments when the user choose whole amount for one payment
        // then the second should be removed
        await this.$store.dispatch('payments/removeMethodFromCurrentPayment', methodToBeRemovedId);
      } else {
        // remove current payment method and recalculate the
        // amount for the second method (if exists), then update the current payment
        // so the whole amount can be assigned to remaining method
        await this.$store.dispatch('payments/removeMethodFromCurrentPayment', this.paymentMethodId);
        const paymentRequestMethods = this.currentPayment.paymentRequestMethods
          .filter((method) => method.paymentMethodId !== this.paymentMethodId);
        if (paymentRequestMethods.length > 0) {
          const data = await recalculateAmount({
            paymentRequestMethods,
            totalAmount: this.currentPayment.amount,
          });
          const totalAmount = data.totalToBeCharged;
          this.$store.dispatch('payments/updateMethodInCurrentPayment', {
            paymentMethodId: paymentRequestMethods[0].paymentMethodId,
            totalAmount,
          });
        }
      }

      if (this.error) {
        await this.$store.commit('payments/updateCurrentPayment', {
          error: undefined,
        });
      }

      this.onHideEditModal();
    },

    onHideEditModal() {
      this.showEditModal = false;
    },

    onUpdatePaymentAmount(totalAmount) {
      this.$store.dispatch('payments/updateMethodInCurrentPayment', {
        paymentMethodId: this.paymentMethodId,
        totalAmount,
      });

      if (this.error) {
        this.$store.commit('payments/updateCurrentPayment', {
          error: undefined,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 600px;
  font-size: 16px;
  margin-bottom: 0;

  & + .wrapper {
    margin-top: calc(var(--grid-unit) * 2);
  }
}

.method-card {
  display: block;
  width: 100%;
  padding: calc(var(--grid-unit) * 1.5);
  padding-bottom: var(--grid-unit);
  font-variant-numeric: lining-nums;
  color: var(--periwinkle-80);
  text-align: left;
  background: var(--periwinkle-20);
  border: 1px solid transparent;
  border-radius: calc(var(--grid-unit) * 2);

  &:focus {
    outline: none;
    -webkit-box-shadow: 0 0 0 3px var(--periwinkle-50);
    box-shadow: none;
    box-shadow: 0 0 0 3px var(--periwinkle-50);
  }

  &:disabled {
    color: var(--periwinkle-50);
    box-shadow: unset;
  }

  &:not([disabled]) {
    &:hover {
      border-color: var(--periwinkle-50);
    }

    &:active {
      border-color: var(--periwinkle-50);
      box-shadow: unset;
    }
  }

  &--fsa {
    color: var(--gold-80);
    background: var(--gold-20);
    box-shadow: 0 1px 0 var(--gold-30);

    &:disabled {
      color: var(--gold-50);
      background: var(--gold-0);
    }

    &:not([disabled]) {
      &:hover,
      &:active {
        border-color: var(--gold-50);
      }
    }
  }

  &--error {
    color: var(--rust-80);
    background: var(--rust-0);
    box-shadow: 0 1px 0 var(--rust-50);

    &:disabled {
      color: var(--rust-50);
      background: var(--rust-0);
    }

    &:not([disabled]) {
      &:hover,
      &:active {
        border-color: var(--rust-50);
      }
    }
  }

  $icon-width: 20px;

  &__summary {
    display: flex;
    flex-direction: row;
  }

  &__icon {
    flex-basis: $icon-width;
    margin-right: var(--grid-unit);
  }

  &__name {
    flex-grow: 1;
    padding: 0;
    margin-right: calc(var(--grid-unit) * 4);
    font-weight: 500;
    margin-top: -3px;
  }

  &__amount {
    padding: 0;
    word-break: keep-all;
  }

  &__additional-details {
    max-width: none;
    min-height: calc(var(--grid-unit) * 0.25);
    padding: 0;
    margin-top: calc(var(--grid-unit) * 0.25);
    margin-left: calc(var(--grid-unit) + $icon-width);
  }
}

</style>
