<template>
  <div>
    <slot :openModal="openModal">
      <system-button
        class="add-method-button"
        style-type="secondary"
        @click="openModal"
        small>
        + Add a new payment method
      </system-button>
    </slot>
    <modal
      :is-open="isModalOpen"
      @close="closeModal"
    >
      <template #header>
        <h6><span class="fal fa-credit-card"></span> Add a payment method</h6>
      </template>
      <div
        v-if="currentStep === 1"
        class="add-method-modal__payment-types"
      >
        <payment-method-card
          v-if="allowFsa"
          :is-fsa-card="true"
          title="FSA DEBIT CARD"
          helper-text="Use your FSA funds easily from your debit card and skip the claim and
            reimbursement. Service fee: 3%"
          @click="setMethodType(paymentMethodTypes.fsaCard)"
          data-cy="add-fsa-card-button"
        />

        <div v-if="directDepositEnabled">
          <plaid-link v-if="!paymentCollection"
            v-bind="{ onSuccess }"
            data-cy="add-bank-account-button"
            @handoff="onHandoff"
          >
            <payment-method-card
              is-bank-account
              title="ONE-CLICK BANK ACCOUNT"
              helper-text="One click setup and no service fees on bank account transfers. Service
                fee: 0%"
            >
            </payment-method-card>
          </plaid-link>
        </div>

        <payment-method-card
          title="CREDIT/DEBIT CARD"
          helper-text="Use any major credit or debit card to pay your care provider. Service
            fee: 3%"
          @click="setMethodType(paymentMethodTypes.stripeCard)"
          data-cy="add-debit-card-button"
        />

        <div class="split">
          <div><i class="fa-solid fa-question"></i></div>
          <div>
            Looking to split this payment between more than one payment method? You can do so in the
            next step.
          </div>
        </div>
      </div>

      <form v-else-if="currentStep === 2" class="add-method-modal__form">
        <system-button style-type="clear" inline @click="backToMethods">
          Select a different source
        </system-button>
        <h3 class="font-heading-20 add-method-modal__heading3">
          Enter {{method.type}} Details
        </h3>
        <k-input
          class="add-method-modal__form__input"
          v-model="method.nameOnCard"
          :error="this.errors.nameOnCard"
          label="Name on card"
          @change="resetErrors('nameOnCard')"
          autofocus
        >
        </k-input>
        <stripe-form
          :elements="method.stripeCard"
          :errors="errors.stripeCard"
          class="add-method-modal__form__input"
        />
      </form>

      <template
        #footer
        v-if="currentStep !== 1">
        <system-button
          style-type="secondary"
          @click="closeModal">
          Cancel
        </system-button>
        <system-button
          :disabled="hasError || loading"
          @click="addCard">
          Add card
        </system-button>
      </template>

      <loader :loading="loading"/>
    </modal>
  </div>
</template>

<script>
import Modal from '@components/modal/modal.vue';
import KInput from '@components/inputs/input.vue';
import SystemButton from '@components/buttons/system-button.vue';
import PlaidLink from '@components/plaid-link/PlaidLink';
import { mapGetters } from 'vuex';
import Loader from '@components/loader/loader';
import { getEnvVar } from '@utils';
import PaymentMethodCard from '../payment-method-card/payment-method-card.vue';
import StripeForm from './stripe-form.vue';

export default {
  components: {
    Modal,
    SystemButton,
    PaymentMethodCard,
    KInput,
    StripeForm,
    PlaidLink,
    Loader,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    methodType: {
      type: String,
    },
    paymentCollection: {
      type: Boolean,
      default: false,
    },
    paymentFlow: {
      type: String,
    },
  },
  watch: {
    showModal(n, o) {
      if (n && !o && !this.isModalOpen) {
        this.openModal();
      }
    },
  },
  data() {
    return {
      isPaymentCollection: this.paymentCollection,
      isModalOpen: !!this.showModal,
      currentStep: this.methodType ? 2 : 1,
      loading: false,
      method: {
        type: this.methodType,
        nameOnCard: undefined,
        stripeCard: {
          cardNumber: undefined,
          cardExpiry: undefined,
          cardCvc: undefined,
        },
      },
      errors: {
        type: false,
        nameOnCard: false,
        stripeCard: {
          cardNumber: undefined,
          cardExpiry: undefined,
          cardCvc: undefined,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      paymentMethodTypes: 'payments/paymentMethodTypes',
      parentUser: 'user/currentParent',
    }),

    directDepositEnabled() {
      return getEnvVar('DIRECT_DEPOSITS_ENABLED') === 'true';
    },

    hasError() {
      switch (this.method.type) {
        case this.paymentMethodTypes.fsaCard:
        case this.paymentMethodTypes.stripeCard:
          return (
            Object.values(this.errors.stripeCard).some((value) => value)
            || this.errors.nameOnCard
          );
        default:
          return false;
      }
    },
    allowFsa() {
      if (this.paymentFlow && ['waitlist', 'seatOffer'].includes(this.paymentFlow)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    setMethodType(type) {
      if (this.method.type && this.method.type !== type) {
        this.resetMethod();
      }
      this.method.type = type;
      this.currentStep = 2;
      if (!this.isModalOpen) {
        this.openModal();
      }
    },

    resetMethod() {
      this.method.type = this.methodType;
      this.method.nameOnCard = undefined;
      const stripeCardKeys = Object.keys(this.method.stripeCard);
      stripeCardKeys.forEach((key) => {
        if (this.method.stripeCard[key]) {
          this.method.stripeCard[key].clear();
        }
      });
      this.resetErrors();
    },

    resetErrors(firstLevelKey, keys) {
      if (firstLevelKey) {
        if (keys) {
          const firstLevel = this.errors[firstLevelKey];
          const keysArray = Array.isArray(keys) ? keys : [keys];
          keysArray.forEach((key) => {
            firstLevel[key] = false;
          });
        } else {
          this.errors[firstLevelKey] = undefined;
        }
      } else {
        const errorKeys = Object.keys(this.errors);
        errorKeys.forEach((key) => {
          const levelKeys = typeof this.errors[key] === 'object' ? Object.keys(this.errors[key]) : undefined;
          this.resetErrors(key, levelKeys);
        });
      }
    },

    validateFsaCardMethod() {
      this.validateStripeMethod();
    },

    validateStripeMethod() {
      this.errors.nameOnCard = !this.method.nameOnCard;
      const { stripeCard } = this.method;
      Object.keys(stripeCard).forEach((key) => {
        const value = stripeCard[key];
        this.errors.stripeCard[key] = this.errors.stripeCard[key] || value === undefined;
      });
    },

    validateCardMethod() {
      switch (this.method.type) {
        case this.paymentMethodTypes.fsaCard:
          return this.validateFsaCardMethod();
        case this.paymentMethodTypes.stripeCard:
          return this.validateStripeMethod();
        default:
          return undefined;
      }
    },

    async addCard() {
      this.loading = true;
      this.validateCardMethod();
      if (this.hasError) {
        this.loading = false;
        return;
      }

      const cardData = (() => {
        const { nameOnCard, stripeCard } = this.method;
        switch (this.method.type) {
          case this.paymentMethodTypes.fsaCard:
          case this.paymentMethodTypes.stripeCard:
            return {
              name: nameOnCard,
              ...stripeCard,
            };
          default:
            return {};
        }
      })();
      try {
        const id = await this.$store.dispatch('payments/addCard', { cardType: this.method.type, cardData, userId: this.parentUser.id });
        this.loading = false;
        this.$emit('method-added', id);
        this.closeModal();
        if (this.isPaymentCollection) {
          await this.$store.dispatch('user/fetchCurrent');
          await this.$router.push({ name: 'collection' });
        }
      } catch {
        this.loading = false;
      }
    },

    backToMethods() {
      this.currentStep = 1;
    },

    openModal() {
      this.isModalOpen = true;
      if (!this.methodType) {
        this.backToMethods();
      } else {
        this.setMethodType(this.methodType);
      }
      this.resetMethod();
    },

    closeModal() {
      if (!this.loading) {
        // modal.vue add overflowY = 'hidden' & is never set back if there is more than 1 modal
        document.body.style.overflowY = 'auto';
        this.isModalOpen = false;
        this.$emit('close');
      }
    },

    onHandoff() {
      this.loading = true;
    },

    async onSuccess(token, data) {
      this.loading = true;
      const methodData = {
        publicToken: token,
        accountId: data.account_id,
        userId: this.parentUser.id,
      };
      try {
        const id = await this.$store.dispatch('payments/addBankAccount', methodData);
        this.loading = false;
        this.$emit('method-added', id);
        this.closeModal();
      } catch {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-method-button {
  margin-top: calc(var(--grid-unit) * 2);
}

.add-method-modal {
  &__heading2 {
    margin-bottom: calc(var(--grid-unit) * 5);
  }

  &__heading3:not(:first-child) {
    margin-top: calc(var(--grid-unit) * 2);
  }

  &__description {
    margin-bottom: calc(var(--grid-unit) * 2);
    color: var(--gray-50);
  }

  &__payment-types {
    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: calc(var(--grid-unit) * 2);
    }
  }

  &__buttonsContainer {
    text-align: right;

    & > * {
      margin-left: var(--grid-unit);
    }
  }

  &__form {
    &__input {
      width: 100%;
      max-width: 300px;
    }
  }
}

.split {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: calc(var(--grid-unit) * 2);
  padding: calc(var(--grid-unit) * 1.5);
  padding-bottom: var(--grid-unit);
  border: 1px solid var(--gray-15);
  border-radius: calc(var(--grid-unit) * 2);
  font-size: 16px;
  margin-top: var(--grid-unit)
}
</style>
